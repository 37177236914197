import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ChatContext,
  UserDetailsContext,
  classNames,
  createUserIfEmailNotExist,
  getRandomColor,
  groupMessagesByDate,
  sortByTimestamp,
  useOutsideClick,
  userData,
} from "../utils/helper";
import { Link, useHistory } from "react-router-dom";
import { XMarkIcon, ArrowDownIcon } from "@heroicons/react/24/outline";
import WriteMessage from "./LayoutComponents/WriteMessage";
import {
  Timestamp,
  collection,
  doc,
  endBefore,
  getDocs,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";
import Loader from "../component/common/Loader";
import ButtonSpinner from "../component/common/Buttons/ButtonSpinner";
import { Api } from "../api";
import UpdatesAlerts from "./LayoutComponents/UpdatesAlerts";
import Svgs from "../component/common/Svgs";

const RightSidebar = () => {
  const {
    isAccessible,
    profilePicture,
    userFirebaseId,
    setUserFirebaseId,
    setUserChatDetails,
  } = useContext(UserDetailsContext);

  const {
    showChat,
    setShowChat,
    chatWithUser,
    setChatWithUser,
    firstVisible,
    setFirstVisible,
    loading,
    setLoading,
    allConversations,
    setAllConversations,
    flag,
    setFlag
  } = useContext(ChatContext);

  const userD = userData();
  const wrapperRef = useRef(null);
  const history = useHistory();
  const chatPerPageLimit = 10;

  const [chatUser, setChatUser] = useState({});
  const [currentChatId, setCurrentChatId] = useState("");

  const [usersChat, setUsersChat] = useState([]);
  const [chatWithDate, setChatWithDate] = useState([]);
  const [loader, setLoader] = useState(false);
  const [nextPostsLoading, setNextPostsLoading] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);

  const chatContainerRef = useRef(null);
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState(null);
  const [userUnsubscribe, setUserUnsubscribe] = useState(null);
  const [isScrollTop, setIsScrollTop] = useState(false);
  const [loadNewChat, setLoadNewChat] = useState(false);
  const [shuffledColors, setShuffledColors] = useState(getRandomColor());
  const [allUpdatesAlerts, setAllUpdatesAlerts] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setShuffledColors(getRandomColor());
  }, []);

  useEffect(() => {
    if (showChat || flag) {
      setChatLoader(true);
      setChatWithDate([]);
      setUsersChat([]);

      const userId = chatWithUser?.user_id
        ? chatWithUser?.user_id
        : chatWithUser?.uid;
      if (userId && showChat) {
        setFirstVisible(null);

        const tempDetail = {
          user_id: userId.toString(),
          user_name: chatWithUser?.user_name,
        };
        const fetchUser = async () => {
          const tempFirebase = await createUserIfEmailNotExist(
            tempDetail,
            chatWithUser?.profile_picture,
            true
          );

          const tempConversation = tempFirebase?.data;
          tempConversation.id = tempFirebase?.firebaseId;

          selectUser(tempConversation || []);
        };
        fetchUser();
      }
    }

    // eslint-disable-next-line
  }, [showChat, flag]);

  const handleResize = () => {
    if (window.innerWidth < 1280) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);
  });
  const userList = async (isLoad) => {
    if (isLoad) {
      setLoader(true);
    }

    const emailQuery = query(
      collection(db, "users"),
      where("uid", "==", userD?.user_id)
    );

    const userQuerySnapshot = await getDocs(emailQuery);

    if (!userQuerySnapshot.empty) {
      const userConversations = [];
      userQuerySnapshot?.forEach((doc) => {
        userConversations.push({ id: doc.id, ...doc.data() });
      });

      const sortedConversations = sortByTimestamp(
        userConversations[0]?.conversations,
        "updatedAt"
      );

      const users_id_list =
        sortedConversations?.length > 0
          ? sortedConversations?.map((conversation) => conversation?.uid)
          : [];
      getAllChatUserData(users_id_list, sortedConversations);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (userUnsubscribe) {
      userUnsubscribe();
    }

    const newUnsubscribe = onSnapshot(
      query(
        collection(db, "users"),
        where("uid", "==", userD?.user_id?.toString())
      ),
      (snapshot) => {
        const temp = snapshot?.docChanges()[0]?.doc?.data();

        const sortedConversations = sortByTimestamp(
          temp?.conversations,
          "updatedAt"
        );

        setAllConversations(sortedConversations);
      }
    );

    setUserUnsubscribe(() => newUnsubscribe);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      chatContainerRef?.current?.scrollHeight > 0 &&
      usersChat?.length > 0 &&
      usersChat?.length <= 10
    ) {
      scrollToBottom();
    }
    // eslint-disable-next-line
  }, [chatWithDate, chatLoader]);

  const selectUser = async (user) => {
    setChatUser(user);
    setLoading(false);
    setShowChat(true);

    const tempAllConversations = user?.conversations;

    let v = [userFirebaseId, user?.id];
    let v1 = v.sort().join("_");

    setCurrentChatId(v1);

    const index = tempAllConversations.findIndex((e) => e.chatId === v1);

    if (index === -1) {
      setDoc(doc(db, "messages", v1), {
        chatId: v1,
      });

      const currentUser = {
        chatId: v1,
        id: userFirebaseId,
        uid: userD?.user_id,
        name: userD?.user_name,
        unread: 0,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
        profilePicture: profilePicture,
        isShow: false,
      };

      tempAllConversations.push(currentUser);

      await updateDoc(doc(db, "users", user?.id), {
        conversations: tempAllConversations,
      });

      const senderData = {
        chatId: v1,
        id: user?.id,
        uid: user?.uid,
        name: user?.name,
        unread: 0,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
        profilePicture: user?.profilePicture,
        isShow: false,
      };

      const arr = [...(allConversations ? allConversations : []), senderData];

      await updateDoc(doc(db, "users", userFirebaseId), {
        conversations: arr,
      });
    }

    await loadChat(v1);

    await userList(false);
  };
  const loadChat = async (v1) => {
    const subcollectionRef = collection(
      doc(db, "messages", v1),
      "conversations"
    );

    if (firstVisible) {
      const previousChatQuery = query(
        subcollectionRef,
        orderBy("createdAt"),
        endBefore(firstVisible),
        limitToLast(chatPerPageLimit)
      );

      const previousChatQuerySnapshot = await getDocs(previousChatQuery);

      if (previousChatQuerySnapshot?.docs?.length < chatPerPageLimit) {
        setLoadNewChat(false);
      } else {
        setLoadNewChat(true);
      }

      const tempChats = [];

      previousChatQuerySnapshot?.forEach((doc) => {
        tempChats.push({ id: doc?.id, ...doc.data() });
      });

      setFirstVisible(previousChatQuerySnapshot?.docs[0]);

      // const height = chatContainerRef?.current?.scrollTop;

      if (previousChatQuerySnapshot.docs?.length >= chatPerPageLimit) {
        chatContainerRef?.current?.scrollTo({
          top: 500,
        });
      }

      setUsersChat((prevData) => [...tempChats, ...prevData]);
      setChatLoader(false);
      setNextPostsLoading(false);
    } else {
      addChatSnapshot(v1);
    }
  };

  const addChatSnapshot = async (v1) => {
    if (unsubscribe) {
      unsubscribe();
    }

    const subcollectionRef = collection(
      doc(db, "messages", v1 ? v1 : currentChatId),
      "conversations"
    );

    const newChatQuery = query(
      subcollectionRef,
      orderBy("createdAt"),
      limitToLast(chatPerPageLimit)
    );

    const newUnsubscribe = onSnapshot(newChatQuery, (snapshot) => {
      if (snapshot.docs?.length < chatPerPageLimit) {
        setLoadNewChat(false);
      } else {
        setLoadNewChat(true);
      }

      setFirstVisible(snapshot.docs[0]);

      const subcollectionData = [];

      snapshot?.forEach((doc) => {
        subcollectionData.push({ id: doc.id, ...doc.data() });
      });

      setUsersChat(subcollectionData);

      scrollToBottom();

      setChatLoader(false);
      setNextPostsLoading(false);
    });

    setUnsubscribe(() => newUnsubscribe);
  };

  const updateUnread = async () => {
    if (
      allConversations?.length > 0 &&
      userFirebaseId &&
      usersChat?.length > 0
    ) {
      const updatedConversations = allConversations?.map((conversation) => {
        if (conversation?.uid === chatUser?.uid) {
          return {
            ...conversation,
            unread: 0,
          };
        }
        return conversation;
      });
      await updateDoc(doc(db, "users", userFirebaseId), {
        conversations: updatedConversations,
      });
      setAllConversations(updatedConversations);
    }
  };

  useEffect(() => {
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Unsubscribe when the component unmounts
      }
    };
  }, [unsubscribe]);

  const fetchData = async () => {
    const userQuery = query(
      collection(db, "users"),
      where("uid", "==", userD?.user_id)
    );

    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      setUserFirebaseId(userQuerySnapshot.docs[0].id || "");
      setUserChatDetails(userQuerySnapshot.docs[0]?.data());
    }
  };

  useEffect(() => {
    const groupedMessages = groupMessagesByDate(usersChat);
    setChatWithDate(groupedMessages);
    // eslint-disable-next-line
  }, [usersChat]);

  useEffect(() => {
    userList(true);
    fetchData();
    // eslint-disable-next-line
  }, []);

  const scrollToBottom = async () => {
    chatContainerRef?.current?.scrollTo({
      top: chatContainerRef?.current?.scrollHeight,
      behavior: "smooth",
    });

    setIsScrollBottom(false);

    const matchedConversation = allConversations?.find(
      (conversation) => conversation?.uid === chatUser?.uid
    );

    if (matchedConversation?.unread > 0) {
      await updateUnread();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrollTop(false);
      setIsScrollBottom(false);

      const containerElement = chatContainerRef.current;
      if (!containerElement) return;

      const scrollTop = containerElement.scrollTop;
      const scrollHeight = containerElement.scrollHeight;
      const clientHeight = containerElement.clientHeight;

      // Calculate the scroll position and determine whether to show or hide the scroll button
      const scrollThreshold = 200; // Adjust this value as needed
      const isAtBottom =
        scrollTop + clientHeight >= scrollHeight - scrollThreshold;

      setIsScrollBottom(!isAtBottom);

      const isAtTop = scrollTop === 0;

      setIsScrollTop(isAtTop);
    };

    const containerElement = chatContainerRef.current;
    if (containerElement) {
      containerElement.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener when the component is unmounted
    return () => {
      if (containerElement) {
        containerElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (firstVisible && isScrollTop && loadNewChat) {
      setNextPostsLoading(true);
      loadChat(currentChatId);
    }
    // eslint-disable-next-line
  }, [isScrollTop]);

  const formatTime = (timestampObject) => {
    const timeAgo = moment(timestampObject).format("hh:mm A");

    return timeAgo;
  };

  const sortConversations = (tempCon) => {
    const sortedConversations = sortByTimestamp(tempCon, "updatedAt");
    setAllConversations(sortedConversations);
  };

  const handleCloseChat = async () => {
    setShowChat(false);
    setChatWithDate([]);
    setUsersChat([]);
    setIsScrollBottom(false);
    setUnsubscribe(null);
    setIsScrollTop(false);
    setFirstVisible(null);
    await updateUnread();
  };

  useOutsideClick(wrapperRef, () => {
    if (showChat && !isMobile) {
      handleCloseChat();
    }
  });

  const updateProfilePictures = (userDetails, conversations) => {
    userDetails?.forEach((user) => {
      const conversation = conversations?.find(
        (conv) => parseInt(conv?.uid) === user?.user_id
      );
      if (conversation) {
        conversation.profilePicture = user?.profile_picture;
        conversation.is_deleted_user =
          user?.is_user_deleted || !user.is_user_active ? true : false;
      }
    });
    return conversations;
  };

  const getAllChatUserData = (userIds, conversations) => {
    if (userIds?.length > 0) {
      const payload = {
        users_id_list: userIds,
      };

      Api.getChatUsersData(payload)
        .then((response) => {
          if (response?.data?.meta?.code === 200) {
            const updatedConversations = updateProfilePictures(
              response?.data?.detail,
              conversations
            );
            const targetUserIndex = response?.data?.detail?.findIndex(
              (user) =>
                user?.user_id == (chatWithUser?.uid ?? chatWithUser?.user_id)
            );
            setChatWithUser((chatWithUser) => {
              return {
                ...chatWithUser,
                is_deleted_user:
                  response?.data?.detail?.[targetUserIndex]?.is_user_deleted ||
                  !response?.data?.detail?.[targetUserIndex]?.is_user_active
                    ? true
                    : false,
              };
            });
            setAllConversations(updatedConversations || []);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    handleCloseChat();
    history.push("/scheduled-activities");
  };

  const clickUserChat = (person) => {
    setLoading(true);
    setChatWithUser(person);
    if (chatWithUser?.user_id !== person?.uid || !person) {
      setFlag((flag)=>!flag)
    }
    setShowChat(true);
    setFirstVisible(null);
  };
  const getAllUpdatesAlerts = () => {
    Api.getAllUpdatesAlerts().then((response) => {
      if (response?.data?.meta?.code === 200) {
        setAllUpdatesAlerts(response?.data?.detail);
      }
    });
  };

  useEffect(() => {
    getAllUpdatesAlerts();
  }, []);
  return (
    <div className="!pl-10 !h-full !flex !flex-col !gap-y-2">
      {isAccessible ? (
        <>
          {/* updates and alerts section */}
          {allUpdatesAlerts?.length > 0 && (
            <section>
              <div
                className={classNames(
                  "!text-hexitime-primary !font-normal !outline-none !flex !items-center !text-2xl !mb-4"
                )}
              >
                <Svgs
                  icon="BellIcon"
                  fillClass="!mr-2.5 !h-7 !w-7 !flex-shrink-0 !text-hexitime-primary !fill-hexitime-primary"
                />
                <p className="!text-lg">Updates</p>
              </div>

              <UpdatesAlerts
                allUpdatesAlerts={allUpdatesAlerts}
                setAllUpdatesAlerts={setAllUpdatesAlerts}
              />
            </section>
          )}

          {/* conversations section */}
          <section className="!relative" ref={wrapperRef}>
            <div
              className={classNames(
                "!text-hexitime-primary !font-normal !outline-none !flex !items-center !text-2xl xl:!mt-3 !mb-3"
              )}
            >
              <Svgs
                icon="UsersIcon"
                fillClass="!fill-hexitime-primary !mr-3 !h-7 !w-7 !flex-shrink-0 "
              />
              <p className="!text-lg">{`Conversations (${
                allConversations?.filter((item) => item?.isShow)?.length || 0
              })`}</p>
            </div>

            <ul
              className={`!overflow-y-auto 1xl:!max-h-[calc(100vh-460px)] !max-h-[calc(100vh-362px)] ${
                !showChat ? "block" : ""
              }`}
            >
              {loader && <Loader />}
              {allConversations?.map(
                (person, i) =>
                  person?.isShow && (
                    <li
                      key={i}
                      className="!flex !gap-x-4 !py-1.5 !items-center"
                    >
                      <span className="relative inline-block">
                        {person?.profilePicture &&
                        person?.profilePicture !== "null" ? (
                          <img
                            className="h-9 w-9 rounded-full object-cover object-center cursor-pointer"
                            src={person?.profilePicture}
                            alt="user-profile"
                            onClick={() => clickUserChat(person)}
                          />
                        ) : (
                          <div
                            className="self-center flex justify-center items-center w-9 h-9 rounded-full uppercase"
                            style={{
                              background: shuffledColors?.bgColor,
                            }}
                          >
                            <span
                              style={{
                                color: shuffledColors?.textColor,
                              }}
                            >
                              {person?.name?.charAt(0)}
                            </span>
                            <span
                              style={{
                                color: shuffledColors?.textColor,
                              }}
                            >
                              {person?.name?.split(" ")[1] &&
                                person?.name?.split(" ")[1]?.charAt(0)}
                            </span>
                          </div>
                        )}
                        {/* <span className="absolute right-0 top-0 block h-1.5 w-1.5 rounded-full bg-hexitime-secondary ring-2 ring-white" /> */}
                      </span>
                      <div
                        className="min-w-0 flex items-center gap-x-4 cursor-pointer"
                        onClick={() => clickUserChat(person)}
                      >
                        <p className="text-sm font-semibold leading-6 text-gray-900 ">
                          {person?.name}
                        </p>

                        {parseInt(person?.unread) > 0 && (
                          <p
                            type="button"
                            className="rounded-full h-6 w-6 bg-hexitime-primaryRed p-1 text-[0.75rem] text-white shadow-sm focus-firstVisible:outline flex items-center justify-center"
                          >
                            {parseInt(person.unread) > 99
                              ? "99+"
                              : person.unread}
                          </p>
                        )}
                      </div>
                    </li>
                  )
              )}
            </ul>

            <div
              className={`1xl:h-[calc(100vh-435px)] h-[calc(100vh-335px)] fixed bottom-2 w-[340px] ${
                showChat ? "block" : "hidden"
              }`}
            >
              <div className="flex space-x-4 h-full">
                <div className="h-full flex flex-col border shadow-md bg-white rounded-t-lg w-full">
                  <div className="flex items-center justify-between border-b rounded-t-lg 1xl:p-2 p-1.5 bg-hexitime-primary">
                    {loading ? (
                      <div className="animate-pulse flex w-3/4 items-center">
                        <div className="rounded-full bg-hexitime-textColor4 h-9 w-9"></div>
                        <div className="flex-1 pl-4 py-1">
                          <div className="h-4 bg-hexitime-textColor4 rounded"></div>
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={`/profile/${chatUser?.uid}`}
                        className="flex items-center"
                        onClick={() => {
                          handleCloseChat();
                        }}
                      >
                        {allConversations?.find(
                          (user) => user?.id === chatUser?.id
                        )?.profilePicture ? (
                          <img
                            alt="user-profile"
                            className="rounded-full w-9 h-9 object-cover object-center"
                            src={
                              allConversations?.find(
                                (user) => user?.id === chatUser?.id
                              )?.profilePicture
                            }
                          />
                        ) : (
                          <div
                            className="self-center flex justify-center items-center w-9 h-9 rounded-full uppercase"
                            style={{
                              background: shuffledColors?.bgColor,
                            }}
                          >
                            <span
                              style={{
                                color: shuffledColors?.textColor,
                              }}
                            >
                              {chatUser?.name?.charAt(0)}
                            </span>
                            <span
                              style={{
                                color: shuffledColors?.textColor,
                              }}
                            >
                              {chatUser?.name?.split(" ")[1] &&
                                chatUser?.name?.split(" ")[1]?.charAt(0)}
                            </span>
                          </div>
                        )}
                        <div className="pl-4">
                          <p className="hover:underline text-white font-medium text-base">
                            {chatUser?.name}
                          </p>
                        </div>
                      </Link>
                    )}

                    <div>
                      <button
                        className="inline-flex hover:bg-hexitime-primary rounded-full p-2"
                        type="button"
                        onClick={() => handleCloseChat()}
                      >
                        <XMarkIcon
                          className="h-5 w-5 text-white hover:text-hexitime-textColor4 rounded-full"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Close chat popup</span>
                      </button>
                    </div>
                  </div>

                  <div
                    className={`max-h-[calc(100vh-435px)] flex-1 px-4 mt-4 overflow-y-auto overscroll-y-contain w-[calc(100%-7px)] relative ${
                      isScrollBottom ? "" : ""
                    }`}
                    ref={chatContainerRef}
                  >
                    {chatLoader ? (
                      <div className="flex justify-center items-center h-full">
                        <ButtonSpinner />
                      </div>
                    ) : (
                      <>
                        {nextPostsLoading && <ButtonSpinner />}
                        {chatWithDate?.map((group, index) => (
                          <div key={index}>
                            <p
                              className={`date bg-white text-hexitime-textColor2 1xl:py-2 py-1 px-4 rounded-full text-center text-sm `}
                            >
                              {group?.date}
                            </p>
                            {group?.messages.map((message, i) => (
                              <div
                                className={`flex items-start mb-2 ${
                                  message?.senderId === userD?.user_id
                                    ? "flex-row-reverse"
                                    : ""
                                } message`}
                                key={i}
                                data-date={group.date}
                              >
                                {/* <div
                              className={`flex-none flex flex-col items-center space-y-1 ${
                                message?.senderId === userD?.user_id
                                  ? "ml-3"
                                  : "mr-3"
                              }`}
                            >
                              <img
                                alt="user-profile"
                                className="rounded-full w-5 h-5"
                                src={
                                  message?.avatar
                                    ? message?.avatar
                                    : "https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png"
                                }
                              />
                            </div> */}
                                <div className="text-end leading-[1] max-w-[250px]">
                                  <div
                                    className={`flex-1 text-left ${
                                      message?.senderId === userD?.user_id
                                        ? "bg-hexitime-primary text-white"
                                        : "bg-hexitime-textColor5 text-hexitime-textColor1"
                                    } 1xl:p-[10px] px-[10px] py-[5px] rounded-lg relative`}
                                  >
                                    {message?.type === "text" ? (
                                      <p
                                        className="text-sm custom-text-break whitespace-pre-wrap"
                                        dangerouslySetInnerHTML={{
                                          __html: message?.text,
                                        }}
                                      />
                                    ) : (
                                      <p
                                        className="text-sm custom-text-break whitespace-pre-wrap"
                                        dangerouslySetInnerHTML={{
                                          __html: message?.text,
                                        }}
                                        onClick={(event) => {
                                          const target = event.target;
                                          if (
                                            target?.tagName.toLowerCase() ===
                                            "a"
                                          ) {
                                            handleLinkClick(
                                              event,
                                              target?.href
                                            );
                                          }
                                        }}
                                      />
                                    )}
                                  </div>

                                  <span className="text-[10px] text-gray-500 leading-none">
                                    {formatTime(message?.createdAt)}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {!chatWithUser?.is_deleted_user && (
                    <div className={`flex items-center p-2 pt-0 mb-2`}>
                      <div className="w-full mx-2">
                        <WriteMessage
                          chatUser={chatUser}
                          chatLoader={chatLoader}
                          currentUserDocId={userFirebaseId}
                          allConversations={allConversations}
                          sortConversations={sortConversations}
                        />
                      </div>
                    </div>
                  )}
                  {isScrollBottom && !chatLoader && (
                    <div
                      className="rounded-full cursor-pointer border shadow-md shadow-hexitime-textColor4 absolute left-1/2 bg-white bottom-16 p-1 -translate-x-1/2 -translate-y-1/2 z-20"
                      onClick={scrollToBottom}
                    >
                      <ArrowDownIcon className="h-4 w-4 text-hexitime-textColor2" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default RightSidebar;
