import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as SendMessageIcon } from "../../assets/images/send-message.svg";
import { UserDetailsContext, userData } from "../../utils/helper";
import ButtonSpinner from "../../component/common/Buttons/ButtonSpinner";

import { db } from "../../firebase";
import "../../";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

const WriteMessage = ({
  chatUser,
  chatLoader,
  allConversations,
  currentUserDocId,
  sortConversations,
}) => {
  const { userFirebaseId, profilePicture } = useContext(UserDetailsContext);
  const inputRef = useRef(null);

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  // eslint-disable-next-line
  const [isCommentLoader, setIsCommentLoader] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [inputHeight, setInputHeight] = useState(28);

  const userD = userData();
  
  const handleChange = (e) => {
    setMessage(e.target.value);
    setError("");

    if (e?.target?.value.length > 0) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  };

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";

      if (inputHeight < 65) {
        setInputHeight(inputRef.current.scrollHeight);
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
      }
    }
  };

  useEffect(() => {
    if (message === "") {
      setInputHeight(28);
    }
    adjustTextareaHeight();
    // eslint-disable-next-line
  }, [message]);

  const keyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      handleMessageSubmit(e);
    }
  };

  const fetchData = async () => {
    const docRef = doc(db, "users", chatUser?.id);

    const docSnap = await getDoc(docRef);

    const tempConversation = docSnap.data();

    const updatedConversations = tempConversation?.conversations?.map(
      (conversation) => {
        if (conversation?.uid === userD?.user_id) {
          return {
            ...conversation,
            updatedAt: Timestamp.fromDate(new Date()),
            unread: parseInt(conversation?.unread) + 1,
            isShow: true,
          };
        }
        return conversation;
      }
    );

    const userUpdateConversation = allConversations?.map((conversation) => {
      if (conversation?.uid === chatUser?.uid) {
        return {
          ...conversation,
          updatedAt: Timestamp.fromDate(new Date()),
          isShow: true,
        };
      }
      return conversation;
    });

    if (isUpdate) {
      await updateDoc(doc(db, "users", chatUser?.id), {
        conversations: updatedConversations,
      });

      if (currentUserDocId) {
        await updateDoc(doc(db, "users", currentUserDocId), {
          conversations: userUpdateConversation,
        });

        sortConversations(userUpdateConversation);
      }

      setIsUpdate(false);
    }
  };

  useEffect(() => {
    if (chatUser?.id) {
      // fetchData();
    }
    // eslint-disable-next-line
  }, [chatUser]);

  const handleMessageSubmit = async (e) => {
    e.preventDefault();

    if (message.trim() === "") {
      return;
    }

    let v = [userFirebaseId, chatUser?.id];
    let chatId = v.sort().join("_");

    addUser(chatId);
  };

  const addUser = async (chatId) => {
    setMessage("");

    const newCollectionRef = collection(
      db,
      "messages",
      chatId,
      "conversations"
    );

    let urlMessage = "";

    const urlRegex = /(http[s]?:\/\/[^\s]+)/g;
    const matches = message?.match(urlRegex);

    if (matches) {
      let formattedMessage = message;
      matches?.forEach((match) => {
        formattedMessage = formattedMessage?.replace(
          match,
          `<a href="${match}" class="underline" target="_blank" rel="noopener noreferrer">${match}</a>`
        );
      });
      urlMessage = formattedMessage;
    } else {
      urlMessage = message;
    }

    let messagePayload = {
      createdAt: Date.now(),
      senderName: userD?.user_name,
      text: urlMessage?.trim(),
      senderId: userD?.user_id,
      avatar: profilePicture,
      type: "text",
    };

    await addDoc(newCollectionRef, messagePayload);
    await updateDoc(doc(db, "messages", chatId), {
      chatId: chatId,
    });
    await fetchData();
  };

  useEffect(() => {
    if (inputRef.current && !chatLoader) {
      inputRef.current.focus();
    }
  }, [chatLoader]);

  return (
    <>
      <section className="mt-4">
        <form onSubmit={(e) => handleMessageSubmit(e)}>
          <div
            className={`relative mt-2 rounded-md shadow-sm flex border border-hexitime-textColor4 px-3 py-1.5 bg-white items-center min-h-[50px] focus-within:border-hexitime-primary ${
              isCommentLoader ? "opacity-50" : ""
            } pr-1 max-h-[300px] overflow-auto`}
          >
            <textarea
              type="text"
              name="account-number"
              ref={inputRef}
              id="account-number"
              className="scroll  p-2.5  resize-none block max-h-[90px] rounded-r-none w-full appearance-none placeholder-hexitime-textColor2 pr-4 placeholder:text-base focus:border-hexitime-primary focus:outline-none focus:ring-hexitime-primary text-base bg-white h-full font-light !min-h-[24px]"
              placeholder="Write your message"
              onKeyDown={keyDown}
              value={message}
              onChange={(e) => handleChange(e)}
              disabled={isCommentLoader}
              autoFocus
              rows="1"
              style={{
                minHeight: inputHeight < 28 ? 28 : inputHeight,
              }}
            />

            {!isCommentLoader ? (
              <div
                className="rounded-full bg-hexitime-primary flex items-center justify-center p-2 cursor-pointer absolute right-[10px] bottom-[10px]"
                onClick={(e) => handleMessageSubmit(e)}
              >
                <SendMessageIcon className="h-4 w-4" />
              </div>
            ) : (
              <ButtonSpinner />
            )}
          </div>
        </form>
        {error && (
          <span className="error text-sm xl:text-base text-hexitime-primaryRed leading-[1px]">
            {error}
          </span>
        )}
      </section>
    </>
  );
};

export default WriteMessage;
