import React from "react";

export default function Svgs(props) {
  const { icon, fillClass } = props;
  switch (icon) {
    case "ProfileIcon":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className={fillClass}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z" />
          <path
            opacity="0.4"
            d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
          />
        </svg>
      );
    case "HomeIcon":
      return (
        <svg
          width="17"
          height="19"
          viewBox="0 0 17 19"
          fill="none"
          className={fillClass}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.00076 17.1843V14.5008C6.00076 13.8208 6.55371 13.2684 7.2386 13.2641H9.75352C10.4415 13.2641 10.9993 13.8178 10.9993 14.5008V17.1765C10.9992 17.7664 11.4785 18.2457 12.0725 18.25H13.7883C14.5897 18.252 15.3589 17.9375 15.9263 17.3756C16.4937 16.8138 16.8125 16.0509 16.8125 15.2553V7.63262C16.8125 6.98997 16.5256 6.38037 16.029 5.96806L10.2001 1.33999C9.18119 0.53048 7.72597 0.556634 6.73722 1.40221L1.03363 5.96806C0.513648 6.36822 0.202856 6.97962 0.1875 7.63262V15.2475C0.1875 16.9057 1.54146 18.25 3.21165 18.25H4.88825C5.17427 18.252 5.4493 18.1407 5.65229 17.9406C5.85528 17.7406 5.96944 17.4684 5.96943 17.1843H6.00076Z" />
        </svg>
      );
    case "ActivitiesIcon":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className={fillClass}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 16.8699V9.25684H21V16.9309C21 20.0699 19.0241 21.9999 15.8628 21.9999H8.12733C4.99561 21.9999 3 20.0299 3 16.8699ZM7.95938 14.4099C7.50494 14.4309 7.12953 14.0699 7.10977 13.6109C7.10977 13.1509 7.46542 12.7709 7.91987 12.7499C8.36443 12.7499 8.72997 13.1009 8.73985 13.5499C8.7596 14.0109 8.40395 14.3909 7.95938 14.4099ZM12.0198 14.4099C11.5653 14.4309 11.1899 14.0699 11.1701 13.6109C11.1701 13.1509 11.5258 12.7709 11.9802 12.7499C12.4248 12.7499 12.7903 13.1009 12.8002 13.5499C12.82 14.0109 12.4643 14.3909 12.0198 14.4099ZM16.0505 18.0899C15.596 18.0799 15.2305 17.6999 15.2305 17.2399C15.2206 16.7799 15.5862 16.4009 16.0406 16.3909H16.0505C16.5148 16.3909 16.8902 16.7709 16.8902 17.2399C16.8902 17.7099 16.5148 18.0899 16.0505 18.0899ZM11.1701 17.2399C11.1899 17.6999 11.5653 18.0609 12.0198 18.0399C12.4643 18.0209 12.82 17.6409 12.8002 17.1809C12.7903 16.7309 12.4248 16.3799 11.9802 16.3799C11.5258 16.4009 11.1701 16.7799 11.1701 17.2399ZM7.09989 17.2399C7.11965 17.6999 7.49506 18.0609 7.94951 18.0399C8.39407 18.0209 8.74973 17.6409 8.72997 17.1809C8.72009 16.7309 8.35456 16.3799 7.90999 16.3799C7.45554 16.4009 7.09989 16.7799 7.09989 17.2399ZM15.2404 13.6009C15.2404 13.1409 15.596 12.7709 16.0505 12.7609C16.4951 12.7609 16.8507 13.1199 16.8705 13.5609C16.8804 14.0209 16.5247 14.4009 16.0801 14.4099C15.6257 14.4199 15.2503 14.0699 15.2404 13.6109V13.6009Z"
          />
          <path
            opacity="0.4"
            d="M3.00293 9.25723C3.01577 8.67023 3.06517 7.50523 3.15803 7.13023C3.63224 5.02123 5.24256 3.68123 7.54442 3.49023H16.4555C18.7376 3.69123 20.3677 5.04023 20.8419 7.13023C20.9338 7.49523 20.9832 8.66923 20.996 9.25723H3.00293Z"
          />
          <path
            d="M8.30465 6.59C8.73934 6.59 9.06534 6.261 9.06534 5.82V2.771C9.06534 2.33 8.73934 2 8.30465 2C7.86996 2 7.54395 2.33 7.54395 2.771V5.82C7.54395 6.261 7.86996 6.59 8.30465 6.59Z"
            className={fillClass}
          />
          <path d="M15.6953 6.59C16.1201 6.59 16.456 6.261 16.456 5.82V2.771C16.456 2.33 16.1201 2 15.6953 2C15.2606 2 14.9346 2.33 14.9346 2.771V5.82C14.9346 6.261 15.2606 6.59 15.6953 6.59Z" />
        </svg>
      );
    case "UsersIcon": {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className={fillClass}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.34933 14.8574C5.38553 14.8574 2 15.4697 2 17.917C2 20.3662 5.364 20.9996 9.34933 20.9996C13.3131 20.9996 16.6987 20.3873 16.6987 17.94C16.6987 15.4908 13.3347 14.8574 9.34933 14.8574Z" />
          <path
            opacity="0.4"
            d="M9.34935 12.5248C12.049 12.5248 14.2124 10.4062 14.2124 7.76241C14.2124 5.11865 12.049 3 9.34935 3C6.65072 3 4.48633 5.11865 4.48633 7.76241C4.48633 10.4062 6.65072 12.5248 9.34935 12.5248Z"
          />
          <path
            opacity="0.4"
            d="M16.1728 7.84824C16.1728 9.19456 15.7599 10.4508 15.0358 11.4943C14.9605 11.6016 15.027 11.7463 15.1581 11.7693C15.3401 11.799 15.527 11.8172 15.7178 11.8211C17.616 11.8699 19.3196 10.6731 19.7902 8.87067C20.4879 6.19625 18.4409 3.79492 15.8333 3.79492C15.5505 3.79492 15.2794 3.82367 15.0152 3.87637C14.979 3.88404 14.9399 3.90128 14.9203 3.93195C14.8949 3.97123 14.9135 4.02202 14.9389 4.05556C15.7227 5.13165 16.1728 6.44156 16.1728 7.84824Z"
          />
          <path d="M21.7795 15.1698C21.4321 14.4444 20.5935 13.947 19.3176 13.7027C18.7158 13.559 17.0857 13.3549 15.57 13.3836C15.5475 13.3865 15.5348 13.4018 15.5329 13.4114C15.5299 13.4267 15.5368 13.4497 15.5661 13.466C16.2667 13.8052 18.9742 15.2809 18.6337 18.3932C18.619 18.5293 18.7295 18.6443 18.8675 18.6251C19.5338 18.5322 21.2481 18.1709 21.7795 17.0479C22.074 16.4538 22.074 15.7639 21.7795 15.1698Z" />
        </svg>
      );
    }
    case "BellIcon": {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={fillClass}
        >
          <path d="M19.7695 11.6453C19.039 10.7923 18.7071 10.0531 18.7071 8.79716V8.37013C18.7071 6.73354 18.3304 5.67907 17.5115 4.62459C16.2493 2.98699 14.1244 2 12.0442 2H11.9558C9.91935 2 7.86106 2.94167 6.577 4.5128C5.71333 5.58842 5.29293 6.68822 5.29293 8.37013V8.79716C5.29293 10.0531 4.98284 10.7923 4.23049 11.6453C3.67691 12.2738 3.5 13.0815 3.5 13.9557C3.5 14.8309 3.78723 15.6598 4.36367 16.3336C5.11602 17.1413 6.17846 17.6569 7.26375 17.7466C8.83505 17.9258 10.4063 17.9933 12.0005 17.9933C13.5937 17.9933 15.165 17.8805 16.7372 17.7466C17.8215 17.6569 18.884 17.1413 19.6363 16.3336C20.2118 15.6598 20.5 14.8309 20.5 13.9557C20.5 13.0815 20.3231 12.2738 19.7695 11.6453Z" />
          <path
            opacity="0.4"
            d="M14.0088 19.2283C13.5088 19.1215 10.4627 19.1215 9.96275 19.2283C9.53539 19.327 9.07324 19.5566 9.07324 20.0602C9.09809 20.5406 9.37935 20.9646 9.76895 21.2335L9.76795 21.2345C10.2718 21.6273 10.8632 21.877 11.4824 21.9667C11.8123 22.012 12.1482 22.01 12.4901 21.9667C13.1083 21.877 13.6997 21.6273 14.2036 21.2345L14.2026 21.2335C14.5922 20.9646 14.8734 20.5406 14.8983 20.0602C14.8983 19.5566 14.4361 19.327 14.0088 19.2283Z"
          />
        </svg>
      );
    }
    case "ReportIcon": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className={fillClass}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
          />
        </svg>
      );
    }
    case "BackIcon": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="4.25 5.25 15.5 13.5"
        >
          <path
            d="M5 12H19"
            stroke="#1CA7D1"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 12L11 18"
            stroke="#1CA7D1"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 12L11 6"
            stroke="#1CA7D1"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    }
    case "DeleteProfileIcon": {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <path
            d="M589.6 240l45.6-45.6c6.3-6.3 6.3-16.5 0-22.8l-22.8-22.8c-6.3-6.3-16.5-6.3-22.8 0L544 194.4l-45.6-45.6c-6.3-6.3-16.5-6.3-22.8 0l-22.8 22.8c-6.3 6.3-6.3 16.5 0 22.8l45.6 45.6-45.6 45.6c-6.3 6.3-6.3 16.5 0 22.8l22.8 22.8c6.3 6.3 16.5 6.3 22.8 0l45.6-45.6 45.6 45.6c6.3 6.3 16.5 6.3 22.8 0l22.8-22.8c6.3-6.3 6.3-16.5 0-22.8L589.6 240zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
            className={fillClass}
          />
        </svg>
      );
    }
    case "File": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            className={fillClass}
          />
        </svg>
      );
    }

    default:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
            className={fillClass}
          />
          <path
            opacity="0.4"
            d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
            className={fillClass}
          />
        </svg>
      );
  }
}
