import "../../assets/css/footer.css";
import X from "../../assets/images/social/x.png";
import Fb from "../../assets/images/social/fb.png";
import Lin from "../../assets/images/social/lin.png";
import Ig from "../../assets/images/social/ig.png";
import Yt from "../../assets/images/social/yt.png";
import WW from "../../assets/images/ww-only.jpg";
import Logo from "../../assets/images/hexitime-logo.svg";

export default function WWFooter() {
  return (
    <div className="pad row snipcss0-0-0-1 snipcss-ROoNR">
      <div className="col-xs-6 col-sm-6 snipcss0-1-1-2">
        <div className="footer__left snipcss0-2-2-3">
          <p className="margin-bottom05 snipcss0-3-3-4">
            <img
              src={WW}
              alt="My Innovation Collective"
              className="footer__left__logo snipcss0-4-4-5"
            />
          </p>
          <h3 className="margin-bottom05 snipcss0-3-3-6 footer-h3">
            Funded by WARWICK INNOVATION DISTRICT © 2024
          </h3>
          <p className="margin-bottom05 snipcss0-3-3-7">
            Address: Warwick Innovation District, University of Warwick,
            Junction Building, 6 University Rd, Coventry, CV4 7EQ
          </p>
          <p className="margin-bottom05 snipcss0-3-3-8">
            <a
              href="https://myinnovationcollective.online/terms-of-use"
              className="footer__left__link snipcss0-4-8-9 underline"
            >
              Terms of use
            </a>
            <a
              href="https://myinnovationcollective.online/timebank-terms-and-conditions"
              className="footer__left__link snipcss0-4-8-10 underline"
            >
              Timebank T&amp;Cs
            </a>
            <a
              href="https://myinnovationcollective.online/privacy-policy"
              className="footer__left__link snipcss0-4-8-11 underline"
            >
              Privacy policy
            </a>
            <a
              href="https://myinnovationcollective.online/cookie-policy"
              className="footer__left__link snipcss0-4-8-12 underline"
            >
              Cookie policy
            </a>
          </p>
          <p className="margin-bottom05 snipcss0-3-3-13">
            <strong className="snipcss0-4-13-14">
              Follow My Innovation Collective
            </strong>
          </p>
          <div className="margin-bottom05 social_buttons social_buttons--greybg snipcss0-3-3-15">
            <a
              href="https://twitter.com/warwick_id"
              target="_blank"
              className="social_buttons__item snipcss0-4-15-16"
            >
              <img
                src={X}
                className="social_buttons__item__icon snipcss0-5-16-17"
              />
            </a>
            <a
              href="https://www.facebook.com/warwickid"
              target="_blank"
              className="social_buttons__item snipcss0-4-15-20"
            >
              <img
                src={Fb}
                className="social_buttons__item__icon snipcss0-5-16-17"
              />
            </a>
            <a
              href="https://www.linkedin.com/school/warwick-innovation-district"
              target="_blank"
              className="social_buttons__item snipcss0-4-15-20"
            >
              <img
                src={Lin}
                className="social_buttons__item__icon snipcss0-5-16-17"
              />
            </a>
            <a
              href="https://www.instagram.com/warwickid"
              target="_blank"
              className="social_buttons__item snipcss0-4-15-20"
            >
              <img
                src={Ig}
                className="social_buttons__item__icon snipcss0-5-16-17"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC_efffB3jqXOfDTSWB9gZEg"
              target="_blank"
              className="social_buttons__item snipcss0-4-15-20"
            >
              <img
                src={Yt}
                className="social_buttons__item__icon snipcss0-5-16-17"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="col-xs-6 col-sm-6 snipcss0-1-1-2">
        <div className="flex items-start">
          <div className="ml-auto space-y-3">
            <h4 className="text-xl font-semibold text-black">Powered by</h4>
            <div className="flex items-center gap-3">
              <a href="http://hexitime.com/">
                <div className="w-36">
                  <img src={Logo} className="w-full" />
                </div>
              </a>
              {/* <div className="w-12 h-12 border border-black/15 rounded-full p-2">
                <a href="https://myinnovationcollective.online/profile/innovation_158">
                  {" "}
                  <img src={footer_second} className="w-full" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
