import React, { useContext, useEffect, useState } from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";

import { ChatContext, UserDetailsContext, userData } from "../utils/helper";
import Svgs from "../component/common/Svgs";

const LeftSidebar = () => {
  const [sidebarData, setSidebarData] = useState([]);
  const { isAccessible, activityCount,allNetworks } = useContext(UserDetailsContext);
  const { setShowChat, setFirstVisible } = useContext(ChatContext);

  const userD = userData();

  useEffect(() => {
    setSidebarData([
      { name: "Home", href: "/home", icon: <Svgs icon="HomeIcon" fillClass="fill-hexitime-primary mr-3 h-6 w-6 flex-shrink-0" />, access: isAccessible },
      {
        name: "My Profile",
        href: `/profile/${userD?.user_id}`,
        icon: <Svgs icon="ProfileIcon" fillClass="fill-hexitime-primary mr-3 h-6 w-6 flex-shrink-0" />,
        access: isAccessible,
      },
      {
        name: "Scheduled Activities",
        href: "/scheduled-activities",
        icon: <Svgs icon="ActivitiesIcon" fillClass="fill-hexitime-primary mr-3 h-6 w-6 flex-shrink-0 " />,
        access: isAccessible,
      },
    ]);
    // eslint-disable-next-line
  }, [isAccessible]);

  return (
    <>
      <nav className="!space-y-1 !pb-4">
        {sidebarData?.map((item, index) =>
          !item?.access ? (
            ""
          ) : (
            <Menu
              key={index}
              item={item}
              i={index}
              activityCount={activityCount}
            />
          )
        )}
      </nav>
      <div className="!border-hexitime-textColor4 !border-t !pt-6  ">
        <p className="!text-hexitime-primary">Networks</p>
        <div className="!max-h-[calc(100vh-340px)] !h-full !overflow-auto !mt-3">
          {allNetworks?.map((network, i) => (
            <Link
              to={`/network/${network?.id}`}
              key={i}
              className="!flex !items-start !mt-3 !cursor-pointer"
              onClick={() => {
                setShowChat(false);
                setFirstVisible(null);
              }}
            >
              <img
                src={network?.logo}
                alt="sidebar_icon"
                className={`!mr-3 !h-8 !w-8 !flex-shrink-0 !text-hexitime-primary !rounded-full`}
              />
              <p className="!text-hexitime-textColor2 !text-base !first-letter:uppercase !self-center">
                {network?.name}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default LeftSidebar;
