/* eslint-disable */
import { createContext, useEffect } from "react";
import toast from "react-hot-toast";
import { DEADOMAINS } from "./dea-domains";
import {
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
  updateDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";

import { db } from "../firebase";
import moment from "moment";
import { AVATAR_COLORS_ARRAY } from "./constants";
import { Api } from "../api";
import * as ReactGA from "react-ga";

export const MaxCharLimitLongText = 1000;
export const getLocalStorageItem = (key) => localStorage.getItem(key);
export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, value);
export const removeLocalStorageItem = (key) => localStorage.removeItem(key);
export const cleanLocalStorage = () => localStorage.clear();
export const getJWTToken = () => "Bearer " + localStorage.getItem("token");
export const getZitadelToken = () =>
  localStorage.getItem("zitadel_user_access_token");
export const getDeviceToken = () => localStorage.getItem("deviceToken");
export const userData = () => JSON.parse(localStorage.getItem("userData"));

export const extractIds = (items = []) => {
  const ids = [];

  if (items?.length > 0) {
    items?.map((item) => {
      if (item?.user_id) {
        ids.push(item?.user_id);
      } else {
        ids.push(item?.id);
      }
    });
  }

  return ids;
};

export const errorToast = (msg, toastId = "") =>
  toast.error(msg, {
    duration: 3000,
    id: toastId,
  });

export const successToast = (msg, duration = 3000) =>
  toast.success(msg, {
    duration,
  });

export const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const capitalize = (value) => {
  if (value) {
    let lowerCase = value?.toLowerCase();
    return lowerCase.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  }
};

export const getFileType = (value) => {
  const fileExtension = value?.name?.split(".");
  const finalType = `${value?.type?.split("/")[0]}/${
    fileExtension[fileExtension?.length - 1]
  }`;
  return finalType;
};

export const RegisterContext = createContext();

export const UserDetailsContext = createContext();

export const ChatContext = createContext({
  chatPopupData: null,
  openChatPopup: () => {},
});

export const DeadDomainEmail = (email) => {
  const emailDomain = "@" + email.split("@")[1];
  return DEADOMAINS.includes(emailDomain);
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const getMaxDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();

  // Add leading zeros if month or day is less than 10
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};

export const addToRemovedList = (removedList, selectedList) => {
  const updatedRemovedList = removedList?.filter((item) => {
    return selectedList?.some((selectedItem) => selectedItem?.id === item?.id);
  });

  return updatedRemovedList;
};

export const truncateDescription = (description, MAX_DESCRIPTION_LENGTH) => {
  if (description && description.length > MAX_DESCRIPTION_LENGTH) {
    return `${description.slice(0, MAX_DESCRIPTION_LENGTH)}....`;
  }
  return description;
};

export const getFileDetails = (file, validFilesArray) => {
  const fileName = file?.name;
  const fileSizeInBytes = file?.size;
  const kilobytes = Math.floor(fileSizeInBytes / 1024);
  const megabytes = Math.floor(kilobytes / 1024);

  let fileSize;
  if (megabytes >= 1) {
    fileSize = `${megabytes}MB`;
  } else {
    fileSize = `${kilobytes}KB`;
  }

  const inFile = {
    file: file,
    name: fileName,
    fileType: file?.type,
  };

  validFilesArray.push(inFile);

  const newFileName =
    fileName.slice(0, 12) + "..." + fileName.slice(-8, -4) + fileName.slice(-4);

  return {
    name: fileName?.length > 20 ? newFileName : fileName,
    keyName: file?.name,
    size: fileSize,
    status: "pending",
    progress: 0,
  };
};

export const createUserIfEmailNotExist = async (
  detail,
  profilePic,
  isRight = false
) => {
  const emailQuery = query(
    collection(db, "users"),
    where("uid", "==", detail?.user_id)
  );
  const emailQuerySnapshot = await getDocs(emailQuery);

  if (emailQuerySnapshot?.empty) {
    const docuRef = doc(collection(db, "users"));

    const newUser = {
      uid: detail?.user_id,
      name: detail?.user_name,
      createdAt: serverTimestamp(),
      conversations: [],
      profilePicture: profilePic ? profilePic : "",
      unread: "0",
    };

    await setDoc(docuRef, newUser);
    const docSnapshot = await getDoc(docuRef);
    const fireId = docSnapshot.id;

    const temp = {
      firebaseId: fireId,
      isNew: true,
      data: docSnapshot?.data(),
    };

    return temp; // User created successfully
  } else {
    const docu = emailQuerySnapshot?.docs[0];
    const fireId = docu.id;

    let temp = {
      firebaseId: fireId,
      isNew: false,
      data: docu?.data(),
    };

    if (temp?.data?.name == null && detail?.user_name != null) {
      await updateDoc(doc(db, "users", fireId), {
        name: detail?.user_name,
      });
      return { ...temp, data: { ...temp.data, name: detail?.user_name } };
    }
    if (profilePic && !isRight) {
      await updateDoc(doc(db, "users", fireId), {
        profilePicture: profilePic,
      });
    }

    return temp;
  }
};

export const sortByTimestamp = (items, timestampField) => {
  return items?.sort((a, b) => {
    const aTimestamp = moment
      .unix(a?.[timestampField]?.seconds)
      .milliseconds(a?.[timestampField]?.nanoseconds / 1000000);
    const bTimestamp = moment
      .unix(b?.[timestampField]?.seconds)
      .milliseconds(b?.[timestampField]?.nanoseconds / 1000000);
    return bTimestamp - aTimestamp;
  });
};

/*Slider breakpoints*/
export const humanLibraryBreakpoints = [
  {
    breakpoint: 1720,
    settings: {
      slidesToShow: 3.1,
    },
  },
  {
    breakpoint: 1550,
    settings: {
      slidesToShow: 2.8,
    },
  },
  {
    breakpoint: 1420,
    settings: {
      slidesToShow: 2.5,
    },
  },
  {
    breakpoint: 1020,
    settings: {
      slidesToShow: 3.5,
    },
  },
  {
    breakpoint: 900,
    settings: {
      slidesToShow: 3.1,
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 2.5,
    },
  },
  {
    breakpoint: 630,
    settings: {
      slidesToShow: 2.1,
    },
  },
  {
    breakpoint: 430,
    settings: {
      slidesToShow: 1.3,
    },
  },
];

export const memberSliderBreakpoints = [
  {
    breakpoint: 1720,
    settings: {
      slidesToShow: 3.1,
    },
  },
  {
    breakpoint: 1550,
    settings: {
      slidesToShow: 2.5,
    },
  },
  {
    breakpoint: 1450,
    settings: {
      slidesToShow: 2.1,
    },
  },
  {
    breakpoint: 1330,
    settings: {
      slidesToShow: 1.8,
    },
  },
  {
    breakpoint: 1260,
    settings: {
      slidesToShow: 2.5,
    },
  },
  {
    breakpoint: 1128,
    settings: {
      slidesToShow: 2.1,
    },
  },
  {
    breakpoint: 1028,
    settings: {
      slidesToShow: 2.8,
    },
  },
  {
    breakpoint: 850,
    settings: {
      slidesToShow: 2.1,
    },
  },
  {
    breakpoint: 650,
    settings: {
      slidesToShow: 1.8,
    },
  },
  {
    breakpoint: 575,
    settings: {
      slidesToShow: 1.2,
    },
  },
];

export const offerSliderBreakpoints = [
  {
    breakpoint: 1720,
    settings: {
      slidesToShow: 3.1,
    },
  },
  {
    breakpoint: 1550,
    settings: {
      slidesToShow: 2.5,
    },
  },
  {
    breakpoint: 1450,
    settings: {
      slidesToShow: 2.1,
    },
  },
  {
    breakpoint: 1330,
    settings: {
      slidesToShow: 1.8,
    },
  },
  {
    breakpoint: 1260,
    settings: {
      slidesToShow: 2.5,
    },
  },
  {
    breakpoint: 1128,
    settings: {
      slidesToShow: 2.1,
    },
  },
  {
    breakpoint: 1028,
    settings: {
      slidesToShow: 2.8,
    },
  },
  {
    breakpoint: 850,
    settings: {
      slidesToShow: 2.1,
    },
  },
  {
    breakpoint: 650,
    settings: {
      slidesToShow: 1.8,
    },
  },
  {
    breakpoint: 575,
    settings: {
      slidesToShow: 1.2,
    },
  },
];

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * AVATAR_COLORS_ARRAY?.length);
  return AVATAR_COLORS_ARRAY[randomIndex];
};

export const groupMessagesByDate = (messages) => {
  const groupedMessages = [];

  messages?.forEach((message) => {
    const date = moment(message?.createdAt).format("DD-MM-YYYY");
    const existingGroup = groupedMessages?.find(
      (group) => group?.date === date
    );

    if (existingGroup) {
      existingGroup?.messages.push(message);
    } else {
      groupedMessages?.push({ date, messages: [message] });
    }
  });

  return groupedMessages;
};

export const fetchOrgInfo = async () => {
  const res = await Api.getOrganizationInfo();
  let root = document.documentElement;
  setLocalStorageItem("logo", res?.data?.detail?.logo);
  for (let key in res?.data?.detail?.json_data) {
    root.style.setProperty(`--${key}`, res?.data?.detail?.json_data[key]);
  }
  return false;
};

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
};

export const handleGtmScriptAdd = () => {
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`;
  script.async = true;
  document.head.appendChild(script);
  const inlineSCript = document.createElement("script");
  inlineSCript.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}')`;
  document.head.appendChild(inlineSCript);
};

export const searchTabFromName = (activityName) => {
  const activityNameToTabMapping = {
    user: "Users",
    offer: "Offers",
    request: "Requests",
    thread: "Threads",
    network: "Networks",
  };
  return activityNameToTabMapping[activityName];
};

export const promiseWrapper = (promise) => {
  let status = "pending",
    result;

  const e = promise.then(
    (value) => {
      (status = "success"), (result = value);
    },
    (error) => {
      (status = "error"), (result = error);
    }
  );

  return () => {
    switch (status) {
      case "pending":
        throw e;
      case "success":
        return result;
      case "error":
        return result;
      default:
        throw new Error("unknown status");
    }
  };
};

export const getApplicationStatusLabelFromCode = (status) => {
  return status == "0"
    ? "Pending"
    : status == "1"
    ? "Accepted"
    : status == "2"
    ? "Rejected"
    : status == "3"
    ? "Referred"
    : status == "4"
    ? "You Left Yourself"
    : status == "5"
    ? "Admin Removed You"
    : "In Progress";
};

export const isValidFileSize = (file, sizeLimit) => {
  if (file?.size > sizeLimit) {
    return false;
  }
  return true;
};
