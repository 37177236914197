import { lazy } from "react";

const Login = lazy(() => import("../container/Login"));
const Register = lazy(() => import("../container/Register/Register"));
const ForgotPassword = lazy(() => import("../container/ForgotPassword"));
const ResetPassword = lazy(() => import("../container/ResetPassword"));
const Home = lazy(() => import("../container/Home/Home"));
const Profile = lazy(() => import("../container/Profile/Profile"));
const ScheduledActivities = lazy(() =>
  import("../container/ScheduledActivities/ScheduledActivities")
);
const Settings = lazy(() => import("../container/Settings/Settings"));
const MobileChats = lazy(() => import("../container/MobileChats/MobileChats"));
const UpdatesAndAlerts = lazy(() =>
  import("../container/UpdatesAndAlerts/UpdatesAndAlerts")
);

const CreateContent = lazy(() => import("../container/Home/Create/ORTContent"));
const OfferRequestDetail = lazy(() =>
  import("../container/ORTDetail/OfferRequestDetail")
);
const ThreadDetail = lazy(() => import("../container/ORTDetail/ThreadDetail"));

const SearchResults = lazy(() =>
  import("../container/SearchResults/SearchResults")
);
const NetworkDetails = lazy(() => import("../container/Networks/Networks"));
const NetworkApplicationForm=lazy(()=>import("../container/Networks/NetworkApplicationForm"))
const UserApplicationDetails=lazy(()=>import("../container/Networks/ShowUserApplicationDetails"))
const routes = [
  {
    path: "/",
    exact: true,
    name: "Login",
    component: Login,
    private: false,
  },
  {
    path: "/register",
    exact: true,
    name: "Register",
    component: Register,
    private: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "ForgotPassword",
    component: ForgotPassword,
    private: false,
  },
  {
    path: "/reset-password",
    exact: true,
    name: "ResetPassword",
    component: ResetPassword,
    private: false,
  },
  {
    path: "/home",
    exact: true,
    name: "Home",
    component: Home,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/profile/:id",
    exact: true,
    name: "Profile",
    component: Profile,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/scheduled-activities",
    exact: true,
    name: "Scheduled Activities",
    component: ScheduledActivities,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/settings",
    exact: true,
    name: "Settings",
    component: Settings,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/chats",
    exact: true,
    name: "Chats",
    component: MobileChats,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/updates-alerts",
    exact: true,
    name: "Updates And Alerts",
    component: UpdatesAndAlerts,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/home/create-offer",
    exact: true,
    name: "Create Offer",
    component: CreateContent,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/home/create-request",
    exact: true,
    name: "Create Request",
    component: CreateContent,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/home/create-thread",
    exact: true,
    name: "Create Thread",
    component: CreateContent,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/profile/offer-detail/:id",
    exact: true,
    name: "Offer Detail",
    component: OfferRequestDetail,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/profile/request-detail/:id",
    exact: true,
    name: "Request Detail",
    component: OfferRequestDetail,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/profile/thread-detail/:id",
    exact: true,
    name: "Thread Detail",
    component: ThreadDetail,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/home/search-results",
    exact: true,
    name: "Search Results",
    component: SearchResults,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/network/:id",
    exact: true,
    name: "Network Name",
    component: NetworkDetails,
    private: true,
    onlyAdmin: false,
  },
  {
    path:"/network/:id/join",
    exact:true,
    name:"Network Join",
    component:NetworkApplicationForm,
    private:true,
    onlyAdmin:false
  },
  {
    path:"/network/:id/review/user/application",
    exact:true,
    name:"Network Join",
    component:UserApplicationDetails,
    private:true,
    onlyAdmin:false
  }
  // {
  //   path: "/shuri-network",
  //   exact: true,
  //   name: "Network Name",
  //   component: NetworkDetails,
  //   private: false,
  //   onlyAdmin: true,
  // },
];
export default routes;
