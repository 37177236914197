import React from "react";

const Divider = () => {
  return (
    <div className="relative flex">
      <div
        className="absolute top-0 bottom-0 left-0 right-0 flex items-center"
        aria-hidden="true"
      >
        <div className="w-full border-t border-hexitime-textColor5" />
      </div>
    </div>
  );
};

export default Divider;
